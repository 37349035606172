<template>
    <div>
        <v-data-table
            :headers="Headers"
            :items="Items"
            :hide-default-footer="!ToLinked"
            :item-class="getClassColor"
            :options.sync="pagination"
            :server-items-length="TotalItems"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(TotalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
            <template v-slot:[`item.type_transaction`]="{ item }">
                <span>{{ formatTypeTransaction(item.type_transaction) }}</span>
            </template>
            <template v-slot:[`item.type_manager`]="{ item }">
                <span>{{ formatManagerType(item.type_manager) }}</span>
            </template>

            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{ formatLigations(item.linked_to, item.total_vinculations) }}</span>
            </template>
            <template v-if="ToLinked" v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            color="black"
                            class="mr-2"
                            @click="link(item)"
                            >mdi-link-variant</v-icon
                        >
                    </template>
                    <span>Vincular</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { formatDate, formatMoney } from '@util/format_util'
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodConciliationBankMovimentsDatatable',
    props: {
        Headers: Array,
        Items: Array,
        TotalItems: Number || 1,
        ToLinked: Boolean,
    },
    computed: {
        ...mapState('period_conciliation_transactions', ['getClassColor']),
        ...mapState('period_conciliation_dialog_vinculation', [
            'callGetRecords',
        ]),
        ...mapFields('period_conciliation_dialog_vinculation', ['pagination']),
    },
    methods: {
       ...mapActions('period_conciliation_dialog_vinculation', [
            'linkRegistry',
            'getRecordsRelated',
        ]),
        link(item) {
            console.log('LINK', item)
            item.tab_linked = 1
            this.linkRegistry(item)
        },
        formatDate(date) {
            return formatDate(date)
        },
        formatMoney(value) {
            return formatMoney(value)
        },
             formatTypeTransaction(type) {
            if (type == 0) {
                return 'Retirada'
            } else if (type == 1){
                return 'Envio'
            }
        },
        formatLigations(linked_to, total_vinculations) {
            return `${linked_to} de ${total_vinculations}`
        },
        formatManagerType(type) {
            let types = [
                'Interno',
                'Externo',
            ]
            if (types[type]) {
                return types[type]
            }
            return 'Sem Gestor'
        },
    },
     watch: {
        pagination: {
            handler() {
                if (this.ToLinked && this.callGetRecords) {
                    console.log("GET RECORDS RELATED", this.callGetRecords)
                    this.getRecordsRelated()
                }
            },
        },
    },
}
</script>